
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PromoBanner"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"promoBanner_promoBanner_BlockType"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"heading"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"lockup"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Asset"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"paragraph"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"buttonTitle"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"buttonDestination"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"sectionHandle"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"uri"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"url"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":231}};
    doc.loc.source = {"body":"#import \"./Asset.graphql\"\n\nfragment PromoBanner on promoBanner_promoBanner_BlockType {\n  __typename\n  heading\n  lockup {\n    ...Asset\n  }\n  paragraph\n  buttonTitle\n  buttonDestination {\n    id\n    sectionHandle\n    uri\n    url\n }\n}","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./Asset.graphql").definitions));


      module.exports = doc;
    
